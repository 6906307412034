@font-face {
  font-family: dicsans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: dicsans, sans-serif;
  font-weight: 700;
  font-style: normal;
}
